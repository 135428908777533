import React, {Component} from 'react';
import './App.css';
import CookieConsent from 'react-cookie-consent';

class App extends Component {

    render() {
        return (
            <div>
                <video autoPlay muted loop id="myVideo">
                    <source src="media/background.mp4" type="video/mp4"/>
                </video>
                <div id="content">
                    <div className="wrap">
                        <div className="linked-in-logo">
                            <a className="linked-in-link"
                               href="https://www.linkedin.com/in/nikolai-besschetnov-83171395/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"/></svg>
                            </a>
                        </div>
                        <div className="title">
                            Nikolai Besschetnov.online
                            <div className="role">
                                Software Solutions Architect @ <a href="https://www.billie.io">Billie.io</a>
                            </div>
                        </div>
                    </div>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText="Understood"
                    cookieName="cookie"
                    style={{ background: "rgba(255, 180, 00, .8)", color: "rgba(14, 41, 51, 0.8)"}}
                    buttonStyle={{ background: "rgba(14, 41, 51, 0.8)", color: "#FFF", fontSize: "13px" }}
                    expires={150}
                >
                    This website uses one single cookie to enhance the user experience by showing that this website uses one sin...
                </CookieConsent>
            </div>
        );
    }
}

export default App;
